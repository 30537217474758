import { Injectable } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client/core';
import { FetchPolicy } from '@core/enums/fetch-policy-apollo.enum';
import { GetListParams } from '@core/interfaces/get-list.interface';
import { GetProductListResponse, RemainingResponse } from '@core/interfaces/nomenclature-balances';
import { LocationFilter } from '@core/interfaces/time-window.interface';
import { OffsetPagination } from '@core/interfaces/zone.interface';
import { Apollo, gql } from 'apollo-angular';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NomenclatureBalancesService {
  constructor(private apollo: Apollo) {}

  public getProductList(params: GetListParams<LocationFilter>): Observable<ApolloQueryResult<GetProductListResponse>> {
    return this.apollo.query<GetProductListResponse>({
      query: gql`
        query GetListProduct($pagination: OffsetPaginationInput, $filter: ProductFilterGqlDto) {
          getListProduct(pagination: $pagination, filter: $filter) {
            result {
              article
              createdAt
              depth
              height
              id
              imageUrl
              localeName
              productBrandId
              productCountInMinimalPackage
              productCrmId
              productMinimalPackagesCount
              productPackageCount
              productPartnerId
              translations {
                productDwhName
                productFullName
                productName
                productSize
                productTranslationId
              }
              unitOfMeasurement
              updatedAt
              weight
              width
            }
            total
          }
        }
      `,
      variables: {
        filter: params.filter,
        language: params.locale,
        pagination: params.pagination,
      },
      fetchPolicy: FetchPolicy.NoCache,
    });
  }

  public getRemaining(article: number, pharmacyId: number): Observable<ApolloQueryResult<RemainingResponse>> {
    return this.apollo.query<RemainingResponse>({
      query: gql`
        query GetRemaining($article: Float!, $pharmacyId: Float!) {
          getRemaining(article: $article, pharmacyId: $pharmacyId) {
            article
            createdAt
            id
            markerId
            pharmacyId
            price
            productCrmId
            quantity
            updatedAt
          }
        }
      `,
      variables: {
        article,
        pharmacyId,
      },
    });
  }
}
