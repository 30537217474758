import { GetListParams } from '@core/interfaces/get-list.interface';
import { LocationFilter } from '@core/interfaces/time-window.interface';

export class GetListNomenclatureBalance {
  static readonly type = '[GetListNomenclatureBalance] Get List Nomenclature Balance';

  constructor(public params: GetListParams<LocationFilter>) {}
}

export class GetRemaining {
  static readonly type = '[Get Remaining] Get Remaining';

  constructor(
    public readonly article: number,
    public readonly pharmacyId: number,
  ) {}
}
