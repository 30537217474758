/* eslint-disable */

import { Injectable } from '@angular/core';
import { State, Store, Action, StateContext, Selector } from '@ngxs/store';
import { tap } from 'rxjs';
import { NomenclatureBalancesService } from '@core/services/nomenclature-balances.service';
import { GetListNomenclatureBalance, GetRemaining } from '../action/nomenclature-balances.action';
import { NomenclatureBalance, RemainingProduct } from '@core/interfaces/nomenclature-balances';
import { formatDateWithoutTime } from '@core/helper/request-time-window-helper';

export class NomenclatureBalancesStateModel {
  balances!: Array<NomenclatureBalance>;
  total!: number | null;
  remaningProduct!: RemainingProduct | null;
}

@State<NomenclatureBalancesStateModel>({
  name: 'NomenclatureBalancesState',
  defaults: {
    balances: [],
    total: null,
    remaningProduct: null,
  },
})
@Injectable()
export class NomenclatureBalancesState {
  constructor(
    private store: Store,
    private nomenclatureBalancesService: NomenclatureBalancesService,
  ) {}

  @Selector()
  static getNomenclatureBalances(state: NomenclatureBalancesStateModel): NomenclatureBalance[] {
    return state.balances;
  }

  @Selector()
  static getNomenclatureBalancesTotal(state: NomenclatureBalancesStateModel): number | null {
    return state.total;
  }

  @Selector()
  static getNomenclatureBalancesRemaningItem(state: NomenclatureBalancesStateModel): RemainingProduct | null {
    return state.remaningProduct;
  }

  @Action(GetListNomenclatureBalance)
  public getListNomenclatureBalance(
    ctx: StateContext<NomenclatureBalancesStateModel>,
    { params }: GetListNomenclatureBalance,
  ) {
    return this.nomenclatureBalancesService.getProductList(params).pipe(
      tap((res) => {
        console.log(res);
        const transformedResult = res.data.getListProduct.result.map((item) => ({
          ...item,
          updatedAt: formatDateWithoutTime(item.updatedAt || item.createdAt),
        }));

        ctx.patchState({ total: res.data.getListProduct.total });
        ctx.patchState({ balances: transformedResult });
      }),
    );
  }

  @Action(GetRemaining)
  getRemaining(ctx: StateContext<NomenclatureBalancesStateModel>, { article, pharmacyId }: GetRemaining) {
    return this.nomenclatureBalancesService.getRemaining(article, pharmacyId).pipe(
      tap((res) => {
        if (res && res.data && res.data.getRemaining) {
          const transformedOrder = {
            ...res.data.getRemaining,
            updatedAt: formatDateWithoutTime(res.data.getRemaining.updatedAt || res.data.getRemaining.createdAt),
          };
          ctx.patchState({ remaningProduct: transformedOrder });
        } else {
          ctx.patchState({ remaningProduct: null });
        }
      }),
    );
  }
}
